import React from 'react';
import Main from '../containers/Layout';
import shippingManagementContent from '../../content/pages/shipping-management.yml';
import ShippingManagementContainer from '@/containers/toolkit/ShippingManagementContainer';

export interface ShippingManagementPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    tickedDescription: { item: string }[];
    button: { label: string; url: string };
  };
  viewport2: {
    title: string;
    cards: { label: string; description: string }[];
  };
  viewport3: {
    title: string;
    subTitle: string;
    leftColumnTitle: string;
    rightColumnTitle: string;
    leftColumn: { description1: string }[];
    rightColumn: { description1: string }[];
    bottomText: string;
    button: {
      label: string;
      url: string;
    };
  };
}

const InventoryAndStockManagement: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3 } =
    shippingManagementContent as unknown as ShippingManagementPageContent;
  return (
    <Main>
      <ShippingManagementContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
        }}
      />
    </Main>
  );
};

export default InventoryAndStockManagement;
